import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b1531a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layouts" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_headers = _resolveComponent("headers")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footers = _resolveComponent("footers")!
  const _component_el_backtop = _resolveComponent("el-backtop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_headers),
    _createVNode(_component_router_view),
    _createVNode(_component_footers),
    _createVNode(_component_el_backtop, { onClick: _ctx.onTop }, null, 8, ["onClick"])
  ]))
}