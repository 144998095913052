
import { defineComponent, ref, reactive, onMounted, onBeforeUnmount, } from 'vue';
import headers from './headers/index.vue'
import footers from './footers/index.vue'
export default defineComponent({
    components: { headers, footers },
    methods:{
        onTop(){
            this.$router.replace({
                query:{}
            })
        }
    }
    
});
